import React from 'react';
import Layout from '../components/Layout';
import { SEO } from '../components/seo';
import HelpBusinessSucceed from '../images/about-banner.jpeg'
import TheMrsGoldSite from '../images/the-mrs-gold.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye, faEye, faPeopleGroup, faHandshakeSimple, faGem } from '@fortawesome/free-solid-svg-icons'

const customStyles = {
  backgroundImage: `url(${HelpBusinessSucceed})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#2991ea',
  backgroundPosition: 'center center',
  marginTop: '74px',
  zIndex: 1
}

const aboutGoogolWeb = {
  paddingTop: '120px',
  // background: '#2b81cd',
  transform: 'skew(0, 5deg) translateY(-70px)',
  zIndex: '0' 
}

const rowPadding = {
  padding: "150px 0"
}

export default function About() {
  return (
    <Layout>
      <section className='w-100 position-relative' style={customStyles}>
        <div className='background-overlay'></div>
        <div className='container'>
          <div className='row row-padding' style={rowPadding}>
            <div className='col-12 col-md-6 text-white' style={{zIndex: '1'}}>
              <h1 className='display-5'>Helping your business succeed</h1>
              <p className='fs-5'>
                We believe in the saying that the greatest success is from helping others
                succeed and achieve their dreams. It is our aim to bring your dream website into reality.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='container'>
        <div className='row g-4 justify-content-center' style={{ padding: '100px 0 150px' }}>
          <div className='col-md-4'>
            <div className='card p-4 d-flex align-items-start bg-white text-dark' style={{ height: "260px" }}>
              <FontAwesomeIcon icon={faBullseye} className='fs-1 mb-3 text-primary' />
              <h2 className='h3'>Our Mission</h2>
              <p>
                Make every business visible online through an effective, affordable, and secure website.
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card p-4 d-flex align-items-start bg-white text-dark' style={{ height: "260px" }}>
              <FontAwesomeIcon icon={faEye} className='fs-1 mb-3 text-primary' />
              <h2 className='h3'>Our Vision</h2>
              <p>
                To provide every business no matter how big or small an online presence. And be the number one website provider.
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card p-4 d-flex align-items-start bg-white text-dark' style={{ height: "260px" }}>
              <FontAwesomeIcon icon={faGem} className='fs-1 mb-3 text-primary' />
              <h2 className='h3'>Our Values</h2>
              <p>
                <strong>Quality:</strong>
                We are capable of delivering the best possible outcome for our clients while taking pride in our work.
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card p-4 d-flex align-items-start bg-white text-dark' style={{ height: "260px" }}>
              <FontAwesomeIcon icon={faPeopleGroup} className='fs-1 mb-3 text-primary' />
              <h2 className='h3'>Our Culture</h2>
              <p>
                Connect every business to people
              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='card p-4 d-flex align-items-start bg-white text-dark' style={{ height: "260px" }}>
              <FontAwesomeIcon icon={faHandshakeSimple} className='fs-1 mb-3 text-primary' />
              <h2 className='h3'>Our Commitment</h2>
              <ul>
                <li>Quality</li>
                <li>Affordable</li>
                <li>Fast</li>
                <li>Secure</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

        <section style={aboutGoogolWeb} className='bg-white'>
          <div className='container' style={{transform: 'skew(0, -5deg)'}}>
            <div className='row g-5 pb-5'>
              <div className='col-md-12 col-lg-6'>
                <img src={TheMrsGoldSite} className='w-100' alt='TheMrsGold Site' />
              </div>
              <div className='col-md-12 col-lg-6'>
                <div className='text-dark'>
                  <h1 className='display-6'>About GoogolWeb</h1>
                  <p>Kia Ora!</p>
                  <p>
                    GoogolWeb started during the time when the whole world are in lockdown due to Covid-19. When the whole world was put to a sudden stop, the businesses that thrive when restrictions eased are the ones that could offer online services once their physical stores are closed. This realization motivated the founders of GoogolWeb to come up with an innovative solution to help struggling small to medium sized businesses to be able to get their products and services into the digital world at a small cost. The founders understand that these businesses had already been hit financially and the worst thing to do is to add to their financial burden. The founders are passionate in helping business owners to promote their products and services to a wider audience to boost their revenue. GoogolWeb believe that this is the best way to give back to the community by supporting the very backbone of the NZ economy- our hardworking and resilient business owners. By working together as a community and supporting each other...the GoogolWeb team believes that we could weather storms ahead of us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className='container pb-5'>
          <div className="row justify-content-md-center" style={{ rowGap: "15px" }}>
              <div className='col-lg-3 col-md-4 px-md-3'>
                  <a href='/get-started' className="btn col-12 btn-primary fs-5 py-3 shadow" type="button">Get Started</a>
              </div>
              <div className='col-lg-3 col-md-4 px-md-3'>
                  <a href='/contact-us' className="btn col-12 btn-orange fs-5 py-3 shadow" type="button">Contact Us</a>
              </div>
          </div>
        </div>
 
    </Layout>
  );
}

export const Head = () => (
  <SEO title="About" />
)